export const VERTICAL_OFFSET_SB_RESP_IMG = 500;
export const VERTICAL_OFFSET_RESP_IMG = 500;
export const HORIZONTAL_OFFSET_RESP_IMG = 300;

export const VERTICAL_OFFSET_MOBILE_IMG = 500;
export const HORIZONTAL_OFFSET_MOBILE_IMG = 300;

export const WORDPRESS_THUMB = 95;
export const WORDPRESS_MEDIUM = 276;
export const WORDPRESS_FULL = 768;

export const SRC_SET_SIZES_TILE = '(max-width:768px) 50vw, (max-width:1024px) 33vw, 25vw';
export const SRC_SET_SIZES_RESP_CAROUSEL_TILE = '(max-width:768px) 33vw, 16vw';
export const SRC_SET_SIZES_MOBILE_SWIPER_TILE = '(max-width: 320px) 50vw, 33vw';
export const SRC_SET_SIZES_MOBILE_SWIPER_THUMBNAIL_TILE = '(max-width: 320px) 25vw, 16vw';
