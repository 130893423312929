import { applyMiddleware, combineReducers, createStore } from 'redux';
import thunk from 'redux-thunk';
import { authMobileReducer as authReducer } from './authMobileReducer';
import { sharedRelayVariablesReducer } from './sharedRelayVariablesReducer';
import { sharedIntlVariablesReducer } from './sharedIntlVariablesReducer';
import dblReducers from 'dibs-buyer-layout/exports/dblReducers';

/**
 * Local `app-buyer-finding` reducers that should be used across the app.
 */
export const defaultReducers = {
    authReducer,
    relayVariables: sharedRelayVariablesReducer,
    intlVariables: sharedIntlVariablesReducer,
};

/**
 * Creates a redux store and applys additional middleware. Defaults to creating a store with all reducers.
 *
 * Shouldn't be used on responsive entry files!!! The store there is coming from the DBL.
 */
export function createReduxStore(additionalReducers = {}, state = {}) {
    // TODO: Get rid of the DBL reducers here
    const reducers = combineReducers({ ...defaultReducers, ...dblReducers, ...additionalReducers });
    return createStore(reducers, state, applyMiddleware(thunk));
}

// https://github.com/zalmoxisus/redux-devtools-extension/blob/master/docs/Troubleshooting.md#excessive-use-of-memory-and-cpu
export const actionSanitizer = action => {
    if (!action?.payload) {
        return action;
    }

    switch (action.type) {
        case 'UPDATE_URI_REF':
            return {
                ...action,
                payload: {
                    ...action.payload,
                    event: {
                        key: action?.payload?.event?.key,
                        type: action?.payload?.event?.type,
                    },
                },
            };
        default:
            return action;
    }
};
