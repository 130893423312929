import {
    PrerenderedCheckoutDeferredAction,
    getPrerenderedCheckoutState,
    setPrerenderedCheckoutDeferredActions,
} from './prerenderedCheckoutStore';

export const deferActionIfInBackground = (action: PrerenderedCheckoutDeferredAction): void => {
    const { rootState } = getPrerenderedCheckoutState();

    if (rootState === 'ENABLED_IN_BACKGROUND') {
        setPrerenderedCheckoutDeferredActions(action);
        return;
    }

    action();
};
