import { SET_INTL } from '../actions/sharedIntlVariablesActions';

export function sharedIntlVariablesReducer(state = {}, action) {
    const { type, intl = {} } = action;
    const { locale, localeDomain, localeOrigin } = intl;

    switch (type) {
        case SET_INTL:
            return {
                ...state,
                locale,
                localeDomain,
                localeOrigin,
            };
        default:
            return state;
    }
}
