import { useEffect } from 'react';

let isDisabled = false;

export function disableBeforeUnload(): void {
    isDisabled = true;
}

export const useBeforeUnload = ({
    isActive,
    trackOpen,
}: {
    isActive: boolean;
    trackOpen?: () => void;
}): void => {
    useEffect(() => {
        function handleBeforeUnload(e: BeforeUnloadEvent): void {
            if (!isDisabled) {
                e.preventDefault();
                trackOpen?.();
                e.returnValue = '';
            }
        }
        if (isActive) {
            window.addEventListener('beforeunload', handleBeforeUnload, { capture: true });
        } else {
            window.removeEventListener('beforeunload', handleBeforeUnload, { capture: true });
        }
        return () =>
            window.removeEventListener('beforeunload', handleBeforeUnload, { capture: true });
    }, [isActive, trackOpen]);
};
