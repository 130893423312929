import { SEGMENTS, VERTICALS } from '../constants/uriConstants';
import {
    QP_PAGE,
    QP_ORIGINAL_SEARCH_QUERY,
    QP_FORCE_SEARCH,
} from '../constants/queryParamConstants';
import serverVars from 'server-vars';

export function getSellerPath(sellerLabel: string): string {
    return `/${SEGMENTS.SELLER}/${sellerLabel}/`;
}

export function getHashFragment(uriRef: string): string {
    if (uriRef) {
        return uriRef.match(/#.*/)?.[0] || '';
    }

    return '';
}

export function hasHashFragment(uriRef: string): boolean {
    return !!getHashFragment(uriRef);
}

function addUrlSearchParamsToPath(urlSearchParams: URLSearchParams, uriRef: string): string {
    const hashFragment = getHashFragment(uriRef);
    const [path] = uriRef.replace(hashFragment, '').split('?');

    urlSearchParams.sort();

    let newParamsString = urlSearchParams.toString();
    if (newParamsString) {
        newParamsString = `?${newParamsString}`;
    }

    return `${path}${newParamsString}${hashFragment}`;
}

function addUrlHashFragmentSearchParamsToPath(
    urlSearchParams: URLSearchParams,
    uriRef: string
): string {
    const hashFragment = getHashFragment(uriRef);
    const path = uriRef.replace(hashFragment, '');
    const [hashFragmentPath] = hashFragment.split('?');

    urlSearchParams.sort();

    let newParamsString = urlSearchParams.toString();
    if (newParamsString) {
        newParamsString = `?${newParamsString}`;
    }

    return `${path}${hashFragmentPath}${newParamsString}`;
}

const createUrlSearchParams = (uriRef: string = ''): URLSearchParams => {
    const hashFragment = getHashFragment(uriRef);
    return new URLSearchParams(uriRef.replace(hashFragment, '').split('?')[1]);
};

const createUrlHashFragmentSearchParams = (uriRef: string = ''): URLSearchParams => {
    const hashFragment = getHashFragment(uriRef);
    return new URLSearchParams(hashFragment.split('?')[1]);
};

export const createNewFilterUriRef = (
    uriRef: string,
    filterName: string,
    filterValue: string
): string => {
    const existingUrlSearchParams = createUrlSearchParams(uriRef);
    // ?page= and ?oq= ?fs= should be always removed when filtering
    existingUrlSearchParams.delete(QP_PAGE);
    existingUrlSearchParams.delete(QP_ORIGINAL_SEARCH_QUERY);
    existingUrlSearchParams.delete(QP_FORCE_SEARCH);

    existingUrlSearchParams.set(filterName, filterValue);
    return addUrlSearchParamsToPath(existingUrlSearchParams, uriRef);
};

export const createNewUriRef = (
    uriRef: string = '',
    additionalQueryParams: { [key: string]: string }
): string => {
    const existingUrlSearchParams = createUrlSearchParams(uriRef);

    for (const qpKey in additionalQueryParams) {
        if (additionalQueryParams.hasOwnProperty(qpKey)) {
            existingUrlSearchParams.set(qpKey, additionalQueryParams[qpKey]);
        }
    }

    return addUrlSearchParamsToPath(existingUrlSearchParams, uriRef);
};

export const getNewPaginationUriRef = (uriRef: string, newPage: number): string => {
    let currentUrlSearchParams: URLSearchParams;
    let addUrlParamsToPath;
    const hashFragment = getHashFragment(uriRef);

    if (hashFragment) {
        currentUrlSearchParams = createUrlHashFragmentSearchParams(uriRef);
        addUrlParamsToPath = addUrlHashFragmentSearchParamsToPath;
    } else {
        currentUrlSearchParams = createUrlSearchParams(uriRef);
        addUrlParamsToPath = addUrlSearchParamsToPath;
    }

    if (newPage === 1) {
        currentUrlSearchParams.delete('page');
    } else {
        currentUrlSearchParams.set('page', newPage.toString());
    }

    return addUrlParamsToPath(currentUrlSearchParams, uriRef);
};

export const getQueryParam = (
    uriRef: string = '',
    paramName: string,
    fromHashFragment?: string
): string | null => {
    if (fromHashFragment) {
        return createUrlHashFragmentSearchParams(uriRef).get(paramName);
    }

    return createUrlSearchParams(uriRef).get(paramName);
};

export const deleteQueryParams = (uriRef: string, queryParams: string[] | string): string => {
    const existingUrlSearchParams = createUrlSearchParams(uriRef);
    const queryParamArray = Array.isArray(queryParams) ? queryParams : [queryParams];
    for (const qp of queryParamArray) {
        existingUrlSearchParams.delete(qp);
    }
    return addUrlSearchParamsToPath(existingUrlSearchParams, uriRef);
};

export function addCdnQueryParams(uriRef: string): string {
    const cdnQueryParams = serverVars.get('cdnQueryParams') || {};
    /**
     * Can be null, can't use defaults. TODO: No reason for it to be null, FIX IT! Can prepopulate
     * it when creating the store.
     */
    if (!uriRef) {
        return '';
    }

    return createNewUriRef(uriRef, cdnQueryParams);
}

export const uriIncludesVertical = (uri: string = ''): boolean => {
    const verticals: string[] = Object.values(VERTICALS);
    return !!uri.split('/').find(segment => verticals.includes(segment));
};

export const isDealersShopUri = (uri: string = ''): boolean => {
    const [href] = uri.split('?');
    return !!href.match(/\/dealers\/(.*)\/shop/);
};

export const isMoreFromSeller = (uri: string = ''): boolean => {
    return uri.startsWith('more-from-seller', 1);
};

export function addTrailingSlash(url: string): string {
    return typeof url === 'string' ? url.replace(/\/?(\?|#|$)/, '/$1') : '/';
}

export function addLeadingSlash(url: string): string {
    if (typeof url !== 'string') {
        return '/';
    }
    return url.startsWith('/') ? url : `/${url}`;
}
