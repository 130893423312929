import { UPDATE_TOKEN } from '../actions/authActions';

function getInitialState() {
    return { shouldUpdateToken: false };
}

export function authMobileReducer(state = getInitialState(), action) {
    const { type } = action;

    switch (type) {
        case UPDATE_TOKEN:
            return { ...getInitialState(), shouldUpdateToken: true };
        default:
            return state;
    }
}
