/**
 * intersect K and keyof T as Key then narrow T[Key] with NonNullable. intersect
 * result with Record<K,unknown> to assert that result type includes K keys not
 * in keyof T. this creates a type that can both narrow existing value types of
 * T and extend T to include unknown values with key K.
 */
type MaybeExtend<T, K extends string, IntersectKeys extends keyof T & K = keyof T & K> = {
    [Key in IntersectKeys]: NonNullable<T[Key]>;
} & Record<Exclude<K, IntersectKeys>, unknown>;

export const isRecord = (arg: unknown): arg is Record<string | symbol | number, unknown> =>
    typeof arg === 'object' && !!arg;

export const isRecordWith = <T, K extends string>(
    arg: T,
    ...fields: [K, ...K[]] // at least 1 field arg
): arg is T & MaybeExtend<T, K> =>
    isRecord(arg) && fields.every(field => field in arg && arg[field] !== null);
