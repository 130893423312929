import { Store } from 'redux';
import { localStorage } from 'dibs-browser-storage';
import authModalLoader from 'dibs-buyer-layout/src/utils/AuthModalLoader';
import { getBuyerId } from 'dibs-cookie-jar';
import { USER_EMAIL } from '../constants/userConstants';
import { Environment } from 'react-relay';

let relayEnvironment: Environment;
let reduxStore: Store;

export function sharedLoginCheckSetUp({
    store,
    environment,
}: {
    store: Store;
    environment: Environment;
}): void {
    relayEnvironment = environment;
    reduxStore = store;
}

type Props = {
    onSuccess?: ({
        userId,
        isVerifiedTrade,
    }: {
        userId: string;
        isVerifiedTrade?: boolean;
    }) => void;
    onError?: (e?: string) => void;
    authOptions: {
        flow?: string;
        ga?: { label: string | null };
        shouldPauseAuthFlow?: (state: $TSFixMe) => boolean;
        additionalProps?: {
            item: { firstPhotoWebPath?: string | null; title?: string | null };
        };
    };
};

/**
 * Handles login logic
 * 1) Checks if user is logged-in
 * 2) If logged-in, calls onSuccess callback
 * if passed in
 * 3) If not logged-in, launches auth modal
 * and then calls onSuccess callback if passed in
 */
export function sharedLoginCheck({
    onSuccess = () => {},
    onError = () => {},
    authOptions,
}: Props): void {
    if (!relayEnvironment || !reduxStore) {
        throw new Error('Must call sharedLoginCheckSetUp before using sharedLoginCheck');
    }
    const userId = getBuyerId(document.cookie);
    if (userId) {
        return onSuccess({ userId });
    }
    return authModalLoader
        .show({
            flow: authModalLoader.constants.DEFAULT_FLOW,
            email: localStorage.getItem(USER_EMAIL),
            relayEnvironment,
            ...authOptions,
        })
        .then((res: { err: Error; userId: string; user: { isVerifiedTrade: boolean } }) => {
            if (res.err) {
                return onError();
            }
            return onSuccess({ userId: res.userId, isVerifiedTrade: res.user.isVerifiedTrade });
        })
        .catch((e?: string) => onError(e));
}

export * from 'dibs-buyer-layout/src/authModal/authentication/authFlowConstants';
