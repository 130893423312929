import { isRecordWith } from 'dibs-ts-utils/exports/isRecord';

const EXTENSION_ID = 'opeaocfgeedgimojbnpkibmbadnfgcpb'; // note: this will be different when running extension locally

type GetSchemaTokenResponse = {
    schemaToken: string;
};

function isSValidchemaTokenResponse(response: unknown): response is GetSchemaTokenResponse {
    return isRecordWith(response, 'schemaToken') && typeof response.schemaToken === 'string';
}

// fails silently if not using chrome or extension not open
export function sendMessage(message: $TSFixMe): Promise<unknown> {
    const chrome = (window as $TSFixMe).chrome;
    if (!chrome) {
        return Promise.resolve();
    }
    return new Promise(resolve => {
        chrome?.runtime?.sendMessage(EXTENSION_ID, message, (response: unknown) => {
            // reading lastError prevents the following chrome error when extension is not open - "Could not establish connection. Receiving end does not exist.""
            resolve(chrome?.runtime?.lastError ? null : response);
        });
    });
}

export async function getSchemaToken(): Promise<string | null> {
    const response = await sendMessage('getSchemaToken');
    if (isSValidchemaTokenResponse(response)) {
        return response.schemaToken;
    }
    return null;
}
