// New Listings
export const QP_NEW_LISTINGS = 'latest' as const;
export const QP_NEW_LISTINGS_VALUE_ENABLED = 'true' as const;

// Page size
export const QP_PAGE_SIZE = 'pageSize' as const;
export const QP_PAGE_SIZE_VALUE_DEFAULT = 60 as const;
export const QP_PAGE_SIZE_VALUE_MAX = 120 as const;

// Page count
export const QP_PAGE = 'page' as const;
export const DISPLAY_MAX_NUMBER_OF_PAGES = 50 as const;

// Content Module Preview ID
export const QP_CONTENT_MODULE_ID = '_content:modules' as const;

// L1 Landing Module Preview ID
export const QP_PREVIEW_MODULE_ID = '_preview:modules' as const;

export const QP_PERSONALIZED_FILTER_VALUE_SHIPPING_FREE = 'shipping-free' as const;
export const QP_PERSONALIZED_FILTER_VALUE_WHITE_GLOVE_SHIPPING = 'white-glove-shipping' as const;

export const QP_PERSONALIZED_FILTER = 'personalizedfilter' as const;

export const QP_SEARCH_QUERY = 'q' as const;
export const QP_ORIGINAL_SEARCH_QUERY = 'oq' as const;
export const QP_FORCE_SEARCH = 'fs' as const;
export const QP_CURRENCY = 'currency' as const;
