import { Environment, Store, RecordSource, Network } from 'relay-runtime';
import serverVars from 'server-vars';
import { getBuyerToken } from 'dibs-cookie-jar';
import { createFetcher } from 'dibs-relay-network-layer/client';
import { setupSubscriptions } from 'dibs-relay-network-layer/subscriptionsClient';

const userType = 'buyer';
export function clientRelayEnvironment(records, options) {
    const { locale } = options || {};
    const getQueries = () => ({
        ...getBuyerToken(document.cookie),
        locale: locale || serverVars.get('locale'),
    });

    const { fetchOrSubscribe, socketListeners } = setupSubscriptions({
        getQueries,
        userType,
    });
    const network = Network.create(createFetcher({ getQueries, userType }), fetchOrSubscribe);
    const source = new RecordSource(records);
    const store = new Store(source);

    return {
        environment: new Environment({ network, store }),
        socketListeners,
    };
}
