import { SET_RELAY_VARIABLES, UPDATE_RELAY_VARIABLES } from '../actions/sharedRelayVariableActions';
import { UPDATE_TOKEN } from '../actions/authActions';

export function sharedRelayVariablesReducer(state = { showLoading: false }, action) {
    const { type } = action;

    switch (type) {
        case SET_RELAY_VARIABLES:
            return { variables: { ...action.variables } };
        case UPDATE_RELAY_VARIABLES:
            return {
                ...state,
                variables: {
                    ...state.variables,
                    ...action.variables,
                },
            };
        case UPDATE_TOKEN: {
            const hasUserId = !!action.userId;
            return {
                ...state,
                showLoading: true,
                variables: {
                    ...state.variables,
                    fetchUser: hasUserId,
                    loadPortfolioData: hasUserId,
                    userIds: action.userId ? [action.userId] : null,
                    userId: action.userId,
                },
            };
        }
        default:
            return state;
    }
}
