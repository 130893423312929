import { useEffect, useState } from 'react';
import {
    PrerenderedCheckoutState,
    addStoreUpdateListener,
    getPrerenderedCheckoutState,
} from './prerenderedCheckoutStore';

export const usePrerenderedCheckoutStore = (): PrerenderedCheckoutState => {
    const [hookState, setHookState] = useState(getPrerenderedCheckoutState());

    useEffect(() => {
        const removeStoreUpdateListener = addStoreUpdateListener(
            (state: PrerenderedCheckoutState): void => {
                setHookState({ ...state });
            }
        );

        return removeStoreUpdateListener;
    }, []);

    return hookState;
};
