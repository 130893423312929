export const CUSTOMER_TYPE_TRADE = 'trade';
export const CUSTOMER_TYPE_CONSUMER = 'consumer';
export const CUSTOMER_TYPE_VIP = 'vip';
export const USER_TOKEN = 'userToken';
export const USER_EMAIL = 'userEmail';
export const USER_TYPE = 'userType';
export const USER_EMAIL_TOKEN = 'userEmailToken';
export const GUEST_USER = 'GUEST_USER';
export const EVER_LOGGED_IN = 'eli';
export const TRADE_STATUSES = {
    N_A: 'N_A',
    SUSPECTED: 'SUSPECTED',
    APPLIED_IN_REVIEW: 'APPLIED_IN_REVIEW',
    APPLIED_NOT_ENOUGH_INFORMATION: 'APPLIED_NOT_ENOUGH_INFORMATION',
    APPLIED_REJECTED: 'APPLIED_REJECTED',
    VERIFIED_PRE_APPROVED: 'VERIFIED_PRE_APPROVED',
    VERIFIED_VETTED: 'VERIFIED_VETTED',
};

// length of time before local storage values "expire" as session values
// 4 hrs: (4 * 60 minutes x 60 seconds * 1000 milliseconds)
export const PSEUDO_SESSION_EXPIRES_AT = 14400000;
export const VISIT_COUNT = 'visitCount';
export const REG_PROMPT_LIMIT = 2;
export const PSEUDO_SESSION = 'pseudoSessionVisitCount';
