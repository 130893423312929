import qs from 'qs';
import SV from 'server-vars';
import { getBuyerToken } from 'dibs-cookie-jar';
import { handleLocaleUrl, GLOBAL_CLIENT_ONLY_LOCALE } from 'dibs-intl/exports/urls';
import { getPrerenderedCheckoutState } from '../prerender/prerenderedCheckoutStore';
import { deferActionIfInBackground } from '../prerender/deferActionIfInBackground';
import { disableBeforeUnload } from '../shared/hooks/useBeforeUnload';
import { CHECKOUT_QUERY_PARAMS } from '../constants/checkoutConstants';
import { getCheckoutTrigger } from './checkoutTrackingTyped';

export function getParsedQueryKeys() {
    const { params } = getPrerenderedCheckoutState();
    const queryParams = qs.parse(window.location.search.substr(1));

    return { ...queryParams, ...params };
}

function getPathFromUrl(pathName) {
    const pathArray = window.location.pathname.split('/') || [];
    return pathArray.filter(path => path.indexOf(pathName) > -1)[0] || '';
}

function hasParamInUrl(param) {
    const params = getParsedQueryKeys();
    return params.hasOwnProperty(param);
}

export function getKountUrl(itemId, vertical) {
    const kountParams = Object.assign({ itemId, vertical }, getBuyerToken(document.cookie));
    return ['/soa/ecom-4/3/transaction/data.gif', qs.stringify(kountParams)].join('?');
}

export function getParamFromUrl(param) {
    const params = getParsedQueryKeys();
    return params.hasOwnProperty(param) ? params[param] : undefined;
}

export function getItemIdFromUrl() {
    const idStr = 'id-';
    const idPath = getPathFromUrl(idStr) || '';
    return getParamFromUrl('itemId') || idPath.replace(idStr, '');
}

export function getCheckoutParam(param) {
    return getParamFromUrl(param) || SV.get(`checkoutPostData.${param}`);
}

export function getCheckoutUrl(itemId) {
    const baseUrl = '/checkout/id-';
    const itemPk = itemId || getItemIdFromUrl();

    if (!itemPk) {
        throw new Error(
            'getCheckoutUrl method expects a Backbone model item or to have it in the url.'
        );
    }
    return baseUrl.concat(itemPk);
}

export function isNegotiableOrder() {
    const amount = getCheckoutParam('offerAmount');
    return !!amount && amount !== getCheckoutParam('listPrice');
}

export function isUserComesFromEmail() {
    return (
        getParamFromUrl('utm_medium') === 'email' ||
        getCheckoutParam('userComesFromEmail') === 'true'
    );
}

export function isUserComesFromAcceptedOffer() {
    return getParamFromUrl('acceptOffer') === 'true' || getParamFromUrl('fullPrice') === 'true';
}

export function isUserNegotiating() {
    return !!getParamFromUrl('offerAmount');
}

export function sentMakeOfferModalMessage() {
    return !!getParamFromUrl('sentMakeOfferModalMessage');
}

export function hasAllowWireTransferInUrl() {
    // when allowWireTransfer=true is part of the uri params, allow checkout with wire transfer - MONEY-2591.
    return getParamFromUrl('allowWireTransfer') === 'true';
}

export function shouldVerifyPaymentMethod() {
    // when noVerification is part of the url params, don't verify the payment method.
    return !hasParamInUrl('noVerification');
}

export function hasAllowCheckInUrl() {
    // when allowCheck=true is part of the uri params, allow checkout with check - MONEY-4358.
    return getParamFromUrl('allowCheck') === 'true';
}

// use wrapper for easier spying when testing
export function redirect(url) {
    deferActionIfInBackground(() => {
        // we actually want to go somewhere now, make sure
        // the warning is turned off
        disableBeforeUnload();
        window.location.href = handleLocaleUrl(url, GLOBAL_CLIENT_ONLY_LOCALE);
    });
}

export function goToMessageCenter(buyerId) {
    const itemId = getItemIdFromUrl();
    if (itemId && buyerId) {
        redirect(`/inbox/buyer/${buyerId}/item/${itemId}`);
    } else {
        redirect('/');
    }
}

function getCheckoutConfirmationUrl({ transactionId, itemId, paymentAuthorizationId, guestToken }) {
    const origin = window.location.origin;
    const url = new URL(`/checkout/confirmation/id-${itemId}/${transactionId}/`, origin);

    if (paymentAuthorizationId) {
        url.searchParams.set(
            CHECKOUT_QUERY_PARAMS.PAYMENT_AUTHORIZATION_ID,
            paymentAuthorizationId
        );
        if (guestToken) {
            url.searchParams.set(CHECKOUT_QUERY_PARAMS.GUEST_TOKEN, guestToken);
        }
    }

    return url.toString().replace(origin, '');
}

export function goToConfirmation({ transactionId, itemId, paymentAuthorizationId, guestToken }) {
    redirect(
        getCheckoutConfirmationUrl({
            transactionId,
            itemId: itemId || getItemIdFromUrl(),
            paymentAuthorizationId,
            guestToken,
        })
    );
}

export function goToOMT(transactionId) {
    let url = '/orders/';
    if (transactionId) {
        url = url.concat(`order/${transactionId}`);
    }
    redirect(url);
}

export function goToItemCategory(browseUrl) {
    const url = browseUrl || '/';
    redirect(url);
}

export function reloadCheckout() {
    const params = { ...getParsedQueryKeys() };

    // MONEY-1455 - save checkout trigger in query param for tracking
    if (!params.triggerType) {
        params.triggerType = getCheckoutTrigger();
    }
    const checkoutUrl = getCheckoutUrl();
    const paramsUrl = qs.stringify(params);
    const url = checkoutUrl.concat(`?${paramsUrl}`);

    redirect(url);
}
