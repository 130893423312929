export const pageTypeConstants = {
    ASSOCIATION: 'association',
    ATTRIBUTE: 'attribute',
    BROWSE: 'browse',
    BUY: 'buy',
    COLLECTION: 'collection',
    COLOR: 'color',
    CONTEMPORARY: 'contemporary',
    CREATOR: 'creator',
    DEALER: 'seller',
    DESIGN: 'design',
    LANDING: 'shop',
    LOCATION: 'location',
    MATERIAL: 'material',
    MORE_FROM_SELLER: 'more-from-seller',
    METAL: 'metal',
    NEGOTIATE: 'negotiate',
    ORIGIN: 'origin',
    PERIOD: 'period',
    RECOGNIZED_DEALER: 'recognized-dealer',
    FAIR_WARNING: 'fair-warning',
    SALE: 'sale',
    SEARCH: 'search',
    SECTION: 'section',
    STONE: 'stone',
    STYLE: 'style',
    ROOM: 'room',
    TECHNIQUE: 'technique',
};

export const pdpMetaTypes = {
    unavailable: 'PDP-s',
    available: 'PDP-a',
} as const;
